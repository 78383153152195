import React, { CSSProperties, FC, ReactNode } from 'react'

interface ICardProps {
    index: number
    showCard: boolean
    children: ReactNode | ReactNode[]
    backgroundColor?: string | undefined
}

const Card: FC<ICardProps> = ({ children, showCard, index, backgroundColor = 'rgba(255, 255, 255, 0.06)' }) => {
    const cardStyles: CSSProperties = {
        background: backgroundColor,
        backdropFilter: 'blur(8px)',
        transition: 'ease 300ms all',
        paddingTop: '100px',
        padding: '1rem',
        width: '100%',
        borderRadius: 10,
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)',
        opacity: showCard ? 1 : 0,
        transform: showCard ? `translateY(0)` : `translateY(100px)`,
        transition: showCard ? `ease all 300ms ${Number(index * 150)}ms` : `ease all 300ms`
    }
    return <article style={cardStyles}>{children}</article>
}

export default Card
