import { Link } from 'gatsby'
import React, { FC, useEffect, useState } from 'react'
import Typist from 'react-typist'
import Card from '../components/Card'
import Layout from '../components/layout'
import Seo from '../components/seo'
import '../css/work.css'

interface IWorkProps {}

interface ICards {
    image: string
    title: string
    technologies: string[]
    bgColor: string
}

const CARDS: ICards[] = [
    {
        image: 'cvs-3.svg',
        title: 'CVS / MyActiveHealth Web App',
        technologies: ['HTML5', 'TypeScript', 'JavaScript', 'React', 'Redux', 'Styled Components', 'Git', 'Responsive'],
        bgColor: '#fff'
    },
    {
        image: 'motion-picture-association-globe-logo.svg',
        title: 'MPA / MPA Theater Training App',
        technologies: ['HTML5', 'TypeScript', 'JavaScript', 'React', 'Redux', 'SCSS', 'Git', 'Responsive'],
        bgColor: '#fff'
    },
    {
        image: 'seamgen-logo.svg',
        title: 'Seamgen Website (v2, v3, v4)',
        technologies: [
            'HTML5',
            'TypeScript',
            'JavaScript',
            'React',
            'Redux',
            'Gatsby',
            'SCSS',
            'Git',
            'Responsive',
            'PHP',
            'SEO',
            'jQuery',
            'WordPress'
        ],
        bgColor: '#fff'
    },
    {
        image: 'soccercity-p-500.jpeg',
        title: 'Soccer City Website',
        technologies: [
            'HTML5',
            'CSS3',
            'SCSS',
            'Git',
            'Responsive',
            'PHP',
            'SEO',
            'JavaScript',
            'jQuery',
            'Spanish Locale'
        ],
        bgColor: '#fff'
    },
    {
        image: 'Luxury_Card_-_white_logo.svg',
        title: 'Luxury Card Website',
        technologies: ['HTML5', 'CSS3', 'SCSS', 'Git', 'Responsive', 'SEO', 'JavaScript', 'jQuery'],
        bgColor: '#000'
    },
    {
        image: 'arrowhead-exchange.svg',
        title: 'arrowheadexchange.com / Earthquake Insurance Quote Tool',
        technologies: ['HTML5', 'CSS3', 'SCSS', 'Git', 'TypeScript', ' Angular 7+', 'Responsive'],
        bgColor: '#fff'
    },
    {
        image: 'cogitativo-logo.svg',
        title: 'Cogitativo',
        technologies: ['HTML5', 'CSS3', 'SCSS', 'Git', 'Responsive', 'PHP', 'SEO', 'JavaScript', 'jQuery'],
        bgColor: '#fff'
    },
    {
        image: 'kitu.png',
        title: 'kitu.io Charging system Web App',
        technologies: ['HTML5', 'CSS3', 'SCSS', 'Git', 'AngularJS', 'Responsive'],
        bgColor: '#fff'
    },
    {
        image: 'aladdinbb-logo.svg',
        title: 'Aladdin Bail Bonds Court Appearance Portal',
        technologies: ['HTML5', 'CSS3', 'SCSS', 'Git', 'Responsive'],
        bgColor: '#fff'
    },
    {
        image: 'san-diego-comic-con-international.svg',
        title: 'comic-con.org / Web portal / Admin Portal',
        technologies: ['HTML5', 'CSS3', 'SCSS', 'Git', 'AngularJS', 'Responsive'],
        bgColor: '#fff'
    },
    {
        image: 'votem.webp',
        title: "everyonecounts.com / Grammy's Voting prototype",
        technologies: ['HTML5', 'CSS3', 'SCSS', 'Git', 'AngularJS', 'Responsive'],
        bgColor: '#000'
    },
    {
        image: 'LMRI-Logo_White-2-resize.png',
        title: 'lmr.net/ Lowy Medical Research Institute',
        technologies: ['HTML5', 'CSS3', 'SCSS', 'Git', 'WordPress', 'Responsive', 'Custom Theme'],
        bgColor: '#000'
    },
    {
        image: 'Group_DIZZY_DOCS.png',
        title: 'Dizzydocs Physician Portal / Patient Portal',
        technologies: ['HTML5', 'CSS3', 'SCSS', 'Git', 'AngularJS', 'Responsive'],
        bgColor: '#000'
    },

    {
        image: 'yoseats.jpeg',
        title: 'Yoseats Web App',
        technologies: ['HTML5', 'CSS3', 'SCSS', 'Git', 'Responsive'],
        bgColor: '#fff'
    },
    {
        image: 'sdz-logo-with-circle.svg',
        title: 'San Diego Zoo Email Templates',
        technologies: ['HTML5', 'CSS3', 'SCSS', 'Git', 'Responsive'],
        bgColor: '#fff'
    }
]

enum EProjectTypes {
    All,
    React,
    Angular,
    TypeScript,
    Seo
    // PHP
}

const Work: FC<IWorkProps> = ({}) => {
    const [filter, setFilter] = useState(EProjectTypes[0])
    const [list, setList] = useState<ICards[]>(CARDS)
    const [showCards, setShowCards] = useState<boolean>(false)
    const NOT_ALL_FILTER = filter !== EProjectTypes[0]
    const regExp = new RegExp(`${filter}`, 'i')

    useEffect(() => {
        setTimeout(() => {
            showUICards(CARDS)
        }, 500)
    }, [])

    useEffect(() => {
        setShowCards(false)
        if (filter === EProjectTypes[0]) {
            showUICards(CARDS)
        } else {
            let filteredArray: ICards[] = []
            ;[...CARDS].forEach((item) =>
                item.technologies.map((tech) => (regExp.test(tech) ? filteredArray.push(item) : null))
            )
            showUICards(filteredArray)
        }
    }, [filter])

    function showUICards(cards: ICards[]): void {
        setTimeout(() => {
            setShowCards(true)
            setList(cards)
        }, 700)
    }
    return (
        <Layout>
            <Seo
                title="Home | David R Tello Software Developer"
                description="My Name is David Tello, here is some of the work I had a hand in implementing"
            />
            <menu className="back-menu ">
                <Link to="/" style={{ color: 'rgba(9, 236, 77, 1)' }}>
                    <i className="bi bi-arrow-left" style={{ fontSize: 18 }}></i> Back home
                </Link>
            </menu>
            <section>
                <Typist
                    cursor={{
                        show: false
                    }}
                >
                    <h1 className="work-title">Featured Work</h1>
                </Typist>
            </section>
            <menu style={{ padding: 0 }} className={`work-menu`}>
                {Object.keys(EProjectTypes).map((key, indx) => {
                    const MATCHES_FILTER = filter === EProjectTypes[key]
                    return isNaN(Number(EProjectTypes[key])) && indx !== 0 ? (
                        <button
                            key={`button-${EProjectTypes[key]}`}
                            onClick={() => setFilter(EProjectTypes[key])}
                            className={`${NOT_ALL_FILTER && MATCHES_FILTER ? 'active' : ''}`}
                        >
                            {EProjectTypes[key]}
                        </button>
                    ) : null
                })}
                {NOT_ALL_FILTER && (
                    <button
                        onClick={() => {
                            setList(CARDS)
                            setFilter(EProjectTypes[0])
                        }}
                    >
                        Clear
                    </button>
                )}
            </menu>
            <section className="work-cards-wrap">
                {list.map((card, indx) => {
                    const path = require(`../images/${card.image}`).default

                    return (
                        <Card showCard={showCards} index={indx} key={`card-${indx}`}>
                            <article>
                                <header
                                    style={{
                                        background: `${card.bgColor}`,
                                        marginRight: 16,
                                        minHeight: 220,
                                        minWidth: 220,
                                        padding: '0'
                                    }}
                                >
                                    {path && <img src={path} loading="eager" style={{ width: 200 }} alt="" />}
                                </header>
                                <article
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        justifyContent: 'flex-start'
                                    }}
                                >
                                    <h3>{card.title}</h3>
                                    <ul>
                                        {card.technologies.map((tech, idx2) => {
                                            return (
                                                <li key={`tech-${indx}-${idx2}`} style={{ display: 'inline-block' }}>
                                                    {regExp.test(tech) ? (
                                                        <mark
                                                            style={{
                                                                background: showCards
                                                                    ? 'rgba(9, 236, 77, 0.693)'
                                                                    : 'transparent',
                                                                color: '#fff',
                                                                padding: '2px 4px'
                                                            }}
                                                        >
                                                            {tech}
                                                        </mark>
                                                    ) : (
                                                        <>{tech}</>
                                                    )}
                                                    {idx2 !== card.technologies.length - 1 ? <>,&nbsp;</> : null}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </article>
                            </article>
                        </Card>
                    )
                })}
            </section>
        </Layout>
    )
}

export default Work
